import { Discount } from '@db/base-models';
import { QueryModel } from '@db/query-models/query-model';

export class CouponCode extends QueryModel<CouponCodeData> {
  code: string;
  source: string;
  name: string;
  expirationDate: Date;
  schoolName: string;
  id: string;
  discount: Discount;
  payload?: any;
  canUse: CouponCodeData['canUse'];
  remainingCount?: number;
  usedCount?: number;
  storedCount?: number;
  display?: boolean;

  constructor(input: DataModel<CouponCodeData> & Partial<QueryModelInnerProps>) {
    const {
      id,
      code,
      source,
      name,
      expirationDate,
      queryClient,
      queryKey,
      instanceConstructor,
      className,
      schoolName,
      discount,
      payload,
      canUse,
      remainingCount,
      usedCount,
      storedCount,
      display,
    } = input;
    super({
      queryClient,
      queryKey,
      instanceConstructor: instanceConstructor ?? CouponCode,
      className: className ?? 'CouponCode',
    });

    this.id = id;
    this.code = code ?? '';
    this.source = source;
    this.name = name;
    this.expirationDate = expirationDate;
    this.schoolName = schoolName;
    this.payload = payload;
    this.canUse = canUse && (remainingCount ? Number(remainingCount) : 0) > 0;
    this.remainingCount = remainingCount ? Number(remainingCount) : 0;
    this.usedCount = usedCount ? Number(usedCount) : 0;
    this.storedCount = storedCount ? Number(storedCount) : 0;
    this.display = display ?? true;

    const previousData = input as any;
    if (discount) {
      this.discount = new Discount({
        ...(previousData.price
          ? { discountPercent: previousData.price }
          : { discountPercent: discount.discountPrice }),
        ...(previousData.discountPercent
          ? { discountPercent: previousData.discountPercent }
          : { discountPercent: discount.discountPercent }),
        ...discount,
        typeId: id,
      });
    } else {
      this.discount = new Discount({
        discountPrice: previousData.price ?? 0,
        ...(previousData.discountPercent ? { discountPercent: previousData.discountPercent } : {}),
        discountType: 'coupon',
        discountSource: previousData.type === 'dt-coupon' ? 'dt' : 'simulation',
        promotionName: name,
        typeId: id,
        academyId: previousData.academyId ?? '',
        lessonConditions: previousData.period
          ? [
              {
                periodText: previousData.period.text,
              },
            ]
          : [],
      });
    }
  }

  get isExpired() {
    return new Date(this.expirationDate).getTime() < Date.now();
  }

  get discountAmount() {
    return this.discount.discountAmount;
  }

  public getDiscountPrice(price: number) {
    return this.discount.convertToPrice(price);
  }
}
