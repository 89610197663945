import { type MouseEvent, useCallback, useEffect } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { PAGE_GROUP } from '@constants/page-mapping';

const BackRedirectUrlMapping: Record<keyof typeof PAGE_GROUP, string> = {
  로그인: '/',
  블로그: '/blog',
  필기: '/test',
  프로필: '/profile',
  이벤트: '/profile',
  약관: '/',
  홈: '/',
  구매: '/',
};

/**
 * [id]와 같은 pathname이 있을 경우에 id를 실제 id로 변경합니다.
 */
const replaceAddress = (address: string, id: string) => {
  if (!address) return '/';
  if (address.includes('[id]')) return address.replace('[id]', id);
  return address;
};

export const PAGE_BACK_MAPPING: Record<string, string> = Object.entries(PAGE_GROUP).reduce(
  (acc, [groupKey, groupValue]) => {
    Object.entries(groupValue).map(([key, value]) => {
      if ('backRedirectUrl' in value && typeof value.backRedirectUrl === 'string') {
        acc[key] = value.backRedirectUrl;
      } else {
        acc[key] = BackRedirectUrlMapping[groupKey as keyof typeof PAGE_GROUP];
      }
    });

    return acc;
  },
  {} as Record<string, string>,
);

// next Router로 한번이라도 이동한 적이 있는지 여부를 기록하는 Flag.
export let isRouteActivated = false;

let isFirstLoad = true;

/**
 * Record는 루트 (_app.tsx)에서 사용해야 합니다.
 */
export const useRecordPreviousURL = () => {
  const router = useRouter();

  useEffect(() => {
    // 로그인 페이지로 이동할 때는 기록하지 않습니다.
    if (router.pathname === '/login') return;
    if (isFirstLoad) {
      isFirstLoad = false;
      return;
    }

    isRouteActivated = true;
  }, [router.pathname]);
};

export const useGetFallbackURL = ({
  backRedirectUrl,
}: {
  // 기본 URL을 덮어쓰기 위한 URL
  backRedirectUrl?: string;
}) => {
  const router = useRouter();

  const fallbackUrl = PAGE_BACK_MAPPING[router.pathname];
  return replaceAddress(backRedirectUrl ?? fallbackUrl, router.query.id as string);
};

/**
 * 1. NextJS에서 라우팅이 일어나지 않았는가 ? -> 크롤링 기계가 읽는 상황이거나 외부 유입 유저가 첫 번째로 마주친 페이지임. 이 경우는 뒤로가기 버튼 클릭 시 broswer의 뒤로가기보다, 운전선생의 페이지로 replace 시키는 것이 유저를 잡아둘 수 있음. 또한, 기계가 SEO Map을 그리는 데에 도움이 됨. (읽지 못한다면 dead-end page가 됨)
 * 2. NextJS에서 라우팅이 일어났는가? -> JS가 활성화되었고, 이미 이전 페이지를 접속한 유저. 운전선생 인바운드에 들어온 유저이므로, NextJS의 동작에 맞기는 것이 자연스러움.
 */
export const BackLink = ({
  children,
  backRedirectUrl,
}: {
  children: React.ReactNode;
  backRedirectUrl?: string;
}) => {
  const router = useRouter();
  const address = useGetFallbackURL({ backRedirectUrl });

  const handleBack = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      // nextjs에서 라우팅을 한 번도 처리하지 않은 경우
      if (isRouteActivated) {
        e.preventDefault();
        router.back();
      }
    },
    [router],
  );

  return (
    <Link onClick={handleBack} href={address} replace>
      {children}
    </Link>
  );
};
