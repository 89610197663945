import { BaseModel } from '@db/base-models';
import {
  LESSON_CONTENT,
  LICENSE_CONTENT,
  LessonToLicenseMap,
  replaceLessonCode,
} from 'shared-values';

export class BaseLessonCode extends BaseModel {
  code: LessonCode;

  constructor(code: LessonCode) {
    super('BaseLessonCode');

    this.code = replaceLessonCode(code);
  }

  get licenseCode() {
    return LessonToLicenseMap[this.code];
  }
  get license() {
    return LICENSE_CONTENT[this.licenseCode] ? LICENSE_CONTENT[this.licenseCode].title : '';
  }
  get titleWithEmoji() {
    return LICENSE_CONTENT[this.licenseCode]
      ? LICENSE_CONTENT[this.licenseCode].titleWithEmoji
      : '';
  }
  get variant() {
    return LESSON_CONTENT[this.code] ? LESSON_CONTENT[this.code].display.sub : '';
  }
  get lessonText() {
    return LESSON_CONTENT[this.code] ? LESSON_CONTENT[this.code].display.main : '';
  }

  get label() {
    const variantString =
      this.code === 'ONE_AUTO_NEW' || this.code === 'TWO_AUTO_NEW' || this.code === 'ONE_MANUAL_NEW'
        ? ''
        : this.variant
        ? `(${this.variant})`
        : '';
    return LESSON_CONTENT[this.code]
      ? `${LESSON_CONTENT[this.code].display.main}${variantString}`
      : '';
  }
  public getLessonContent(): LessonContentProperty {
    return LESSON_CONTENT[this.code];
  }
  public getLicenseContent(): LicenseContentProperty {
    return LICENSE_CONTENT[this.licenseCode];
  }
}
