import { db } from 'firebase-config';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { CouponCode } from '../models';

export const getFirstComeCoupon = ({ queryKey, queryClient }: QueryFn<[string, string]>) => {
  const [, academyId] = queryKey;
  const q = query(
    collection(db, 'CouponCode'),
    where('discount.academyId', '==', academyId),
    where('source', '==', 'first-come'),
  );

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    if (querySnapshot.empty) return queryClient.setQueryData(queryKey, undefined);

    const coupon = querySnapshot.docs
      .map((doc) => new CouponCode({ ...(doc.data() as CouponCodeData), id: doc.id }))
      .sort((a, b) => {
        return b.discount.discountPrice - a.discount.discountPrice;
      })[0];

    findMethodAndReplace(coupon, 'toDate');

    queryClient.setQueryData(queryKey, coupon);
  });

  return () => {
    unsubscribe();
  };
};
