'use client';

import { useEffect } from 'react';

import { useGetUser } from '@db/collections';
import { QUERY_KEY } from '@db/constants';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmptyString } from 'shared-values';

import { getFirstComeCoupon } from '../calls';

export const useGetFirstComeCouponCode = (academyId = '') => {
  const queryKey = [QUERY_KEY.FIRST_COME_COUPON, academyId];
  const queryClient = useQueryClient();
  const result = useQuery<CouponCode | null>({
    queryKey,
    queryFn: () => null,
    enabled: false,
    initialData: null,
  });

  const { user } = useGetUser();

  useEffect(() => {
    if (isEmptyString(academyId)) return;

    if (user && user.orders.length > 0) {
      queryClient.setQueryData(queryKey, null);
      return;
    }

    getFirstComeCoupon({ queryClient, queryKey: queryKey as [string, string] });
  }, [academyId, user]);

  return result;
};
