import { useOverlay } from 'design-system';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { HOME_URI } from 'shared-values';

import { isAppApproaching } from '@utils/Common';

const CheckModal = dynamic(
  () => import('@components/CheckModal').then((v) => ({ default: v.CheckModal })),
  {
    ssr: false,
  },
);
export const shareLink = async (
  {
    text,
    url,
    title,
  }: {
    text: string;
    url: string;
    title: string;
  },
  afterClickCopy: () => void,
) => {
  const isAndroidApproach = isAppApproaching({ isAndroidpproaching: true });

  // 안드로이드 동작
  if (isAndroidApproach) {
    await window.flutter_inappwebview.callHandler('ShareNative', `${title}\n\n${text}\n\n${url}`);
    return;
  }
  // 모바일 웹 동작. https에서만 작동
  else if (navigator.share) {
    await navigator.share({
      title,
      text,
      url,
    });
    return;
  }
  // 그 외 fallback
  await navigator.clipboard.writeText(url);
  afterClickCopy();
};

export const useShareLink = ({
  text,
  url,
  title,
}: {
  text: string;
  title: string;
  url?: string;
}) => {
  const overlay = useOverlay();
  const router = useRouter();

  return () =>
    shareLink({ text, title, url: url ?? HOME_URI + router.asPath }, () => {
      overlay.open(({ isOpen, close }) => (
        <CheckModal text={'주소가 복사되었습니다.'} isOpen={isOpen} close={close} />
      ));
    });
};
