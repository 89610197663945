import { InsightfulData, 매출, 유입, 유지, 활성화 } from './events';

let instance: DTLogEvent | null = null;

export class DTLogEvent {
  유입 = new 유입({ user: null, location: null });
  활성화 = new 활성화({ user: null, location: null });
  유지 = new 유지({ user: null, location: null });
  매출 = new 매출({ user: null, location: null });
  InsightfulData = new InsightfulData({ user: null, location: null });

  public user?: User | null;
  public location?: LocationType | null;

  constructor() {
    if (instance) {
      return instance;
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    instance = this;
  }

  public setData({ user, location }: { user?: User; location?: LocationType }) {
    this.user = user ?? this.user;
    this.location = location ?? this.location;

    const params = { user: user ?? this.user, location: location ?? this.location };

    this.유입 = new 유입(params);
    this.활성화 = new 활성화(params);
    this.유지 = new 유지(params);
    this.매출 = new 매출(params);
    this.InsightfulData = new InsightfulData(params);
  }
}

export const LogEvent = new DTLogEvent();
