import { useEffect, useState } from 'react';

import { useGetEventRoom, useGetUser } from 'database';
import { NewIcon } from 'design-system';
import { useRouter } from 'next/router';

const useHandleData = () => {
  const [eventRoomId, setEventRoomId] = useState<string>('');
  const result = useGetEventRoom(eventRoomId);

  const { user } = useGetUser();

  useEffect(() => {
    if (!user) return;
    setEventRoomId(user.eventRoomId);
  }, [user]);

  return result;
};

export const TogetherRoomBottomFloatingBar = ({ close }: { close: () => void }) => {
  const router = useRouter();
  const { data: eventRoom, isLoading, isRoomNotExist } = useHandleData();

  const onRouteRoom = (roomId: string) => {
    router.push(`/event/together/room/${roomId}`);
  };

  useEffect(() => {
    if (isRoomNotExist) {
      close();
    }
  }, [isRoomNotExist]);

  if (!eventRoom || isLoading || isRoomNotExist) return null;

  const { leaderUser } = eventRoom;

  return (
    <div onClick={() => onRouteRoom(eventRoom.id)} className="flex justify-between">
      <div className="text-Body1 text-white">{leaderUser.name}님의 팀 보기</div>
      <div className="flex items-center gap-12">
        <NewIcon icon="arrowright-outlined" size={16} className="fill-new-gray-400" />
      </div>
    </div>
  );
};
