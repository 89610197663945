import deepEqual from 'fast-deep-equal';
import { type Atom, atom } from 'jotai';
import { atomFamily, atomWithReset } from 'jotai/utils';

type RouterState = {
  isReady: boolean;
  lat: string;
  lng: string;
};

type TargetLocationParams = {
  routerState: RouterState;
  myLocation: {
    latitude: number;
    longitude: number;
  } | null;
  savedLatestLocation: {
    latitude: number;
    longitude: number;
  } | null;
};

export const mapCenterMarkerLocationAtom = atomWithReset<LocationType | null>(null);

export const targetLocationSelectorFamilyAtom = atomFamily<
  TargetLocationParams,
  Atom<LocationType | null>
>(
  (param: TargetLocationParams) =>
    atom((get) => {
      const { routerState, myLocation, savedLatestLocation } = param;
      const centerMarkerLocation = get(mapCenterMarkerLocationAtom);

      const { isReady, lat, lng } = routerState;

      const isCoordUrlParamsExist = lat.length && lng.length;

      if (!isReady) {
        return null;
      }

      if (centerMarkerLocation) {
        return centerMarkerLocation;
      }

      if (savedLatestLocation) {
        return savedLatestLocation;
      }

      if (isCoordUrlParamsExist) {
        return { latitude: Number(lat), longitude: Number(lng) };
      }

      return myLocation;
    }),
  deepEqual,
);

export const locationPermissionStateAtom = atom<LocationPermission>('');

export const isShowingNoPermissionStateAtom = atom<boolean>(true);

export const mapZoomStateAtom = atom<number>(11);
