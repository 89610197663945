import { useEffect } from 'react';

import { useRouter } from 'next/router';
import { HOME_URI } from 'shared-values';

interface UseScrollHistoryProps {
  setStorageName: string;
  excludePathnames?: string[];
}

export const useScrollHistory = ({ setStorageName, excludePathnames }: UseScrollHistoryProps) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const pathname = new URL(HOME_URI + url).pathname;
      if (excludePathnames && excludePathnames.includes(pathname)) {
        sessionStorage.removeItem(setStorageName);
        return;
      }

      sessionStorage.setItem(setStorageName, window.scrollY.toString());
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (setStorageName in sessionStorage) {
      window.scrollTo(0, Number(sessionStorage.getItem(setStorageName)));
      sessionStorage.removeItem(setStorageName);
    }
  }, []);
};
