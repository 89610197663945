import { useState } from 'react';

import { NewIcon } from 'design-system';

import { Popover } from './Popover';

export function InfoPopover({
  content,
  side = 'top',
  collisionPadding = 5,
  sideOffset = 0,
  icon,
  ...rest
}: Omit<React.ComponentPropsWithoutRef<typeof Popover>, 'children'> & {
  icon?: React.ComponentPropsWithoutRef<typeof NewIcon>['icon'];
}) {
  const [open, setOpen] = useState(false);
  const handleOpenChange = (open: boolean) => {
    setOpen(open);
  };

  return (
    <Popover
      onClick={() => {
        setOpen(false);
      }}
      open={open}
      onOpenChange={handleOpenChange}
      side={side}
      collisionPadding={collisionPadding}
      sideOffset={sideOffset}
      content={content}
      onPointerDownOutside={(e) => e.preventDefault()}
      onInteractOutside={(e) => e.preventDefault()}
      {...rest}
    >
      <NewIcon
        className="fill-new-gray-400 ml-4 flex"
        icon={icon ? icon : 'questionmark-outlined'}
        size={16}
      />
    </Popover>
  );
}
