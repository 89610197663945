export const 운전선생허용수업: LessonCode[] = [
  'ONE_MANUAL_NEW',
  'ONE_MANUAL_ROAD',
  'ONE_MANUAL_RE',
  'ONE_AUTO_NEW',
  'ONE_AUTO_ROAD',
  'ONE_AUTO_RE',
  'TWO_AUTO_RE',
  'TWO_AUTO_NEW',
  'TWO_AUTO_ROAD',
  'ONE_LARGE',
  'TRANSFORM_TWO_TO_ONE',
  'TWO_SMALL_NEW',
  'TWO_SMALL_HAVE_LICENSE',
  'TWO_SMALL_HAVE_MOTOR',
  'TRAINING_4_HOURS',
  'TRAINING_6_HOURS',
  'TRAINING_10_HOURS',
  'TWO_AUTO_VISIT_TRAINING_8_HOURS',
];
