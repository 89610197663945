'use client';

import { useEffect, useState } from 'react';

import { QUERY_KEY } from '@db/constants';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { auth } from 'firebase-config';
import { onAuthStateChanged } from 'firebase/auth';

import { getUserById } from '../calls';
import { User } from '../models';

const queryKey = [QUERY_KEY.CURRENT_USER];

export const useGetUser = <T extends boolean = false>() => {
  const queryClient = useQueryClient();

  const result = useQuery<User | undefined>({
    queryKey: queryKey,
    queryFn: () => undefined,
    enabled: false,
  });

  const { data: user } = result;

  const setUser = (userData: UserData) => {
    const userModel = new User({ ...userData, queryClient, queryKey });
    queryClient.setQueryData<User>(queryKey, userModel);
    return userModel;
  };

  const setQuery = async (userId: string) => {
    setIsLoading(true);
    const deviceToken = window.deviceToken;
    await queryClient.fetchQuery({
      queryKey,
      queryFn: async () => {
        const firestoreUser = await getUserById(userId, deviceToken);
        // email 필드는 무조건 있다고 가정. Firebase Login 시에 auth를 먼저하고 document 생성을 나중에 하기 때문에
        // 빈 유저가 들어오는 경우가 있어서 추가함.
        if (!firestoreUser || !firestoreUser.email) return null;

        const user = new User({
          ...firestoreUser,
          queryClient,
          queryKey,
        });

        if (user.isManager) {
          const cookieName = 'gtm_internal_user'; // Name of your cookie
          const cookieValue = 'internal'; // Value of your cookie
          let expirationTime = 31104000; // One year in seconds
          expirationTime = expirationTime * 1000; // Converts expiration time to milliseconds
          const date = new Date();
          const dateTimeNow = date.getTime();

          date.setTime(dateTimeNow + expirationTime); // Sets expiration time (Time now + one year)
          const expiryDate = date.toUTCString(); // Converts milliseconds to UTC time string
          document.cookie =
            `${cookieName}=${cookieValue}; SameSite=None; Secure; expires=${expiryDate}; path=/; domain=.` +
            window.location.hostname.replace(/^www\./i, ''); // Sets cookie for all subdomains
        }

        return user;
      },
      staleTime: 0,
    });
    setIsLoading(false);
  };

  const resetUser = async () => await queryClient.resetQueries({ queryKey });

  // 로그인 된 상태에서 refetch를 하고 싶을 때 사용
  const refetchUser = async () => {
    await resetUser();

    const fbUser = auth.currentUser;

    if (!fbUser) return;

    await setQuery(fbUser.uid);
  };

  const [isLoading, setIsLoading] = useState(true);

  const isUserExist = Boolean(user);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const unlisten = onAuthStateChanged(auth, async (fbUser) => {
      if (!fbUser || user) {
        setIsLoading(false);
        return;
      }
      const { uid } = fbUser;

      await setQuery(uid);
    });
    return () => {
      unlisten();
    };
  }, [user, queryClient]);

  return {
    user: user as T extends true ? User : User | undefined,
    setUser,
    resetUser,
    isUserExist,
    isLoading,
    refetchUser,
  };
};
