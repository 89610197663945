import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import 'react-indiana-drag-scroll/dist/style.css';
import 'reactjs-popup/dist/index.css';

import { Analytics } from '@analytics/Analytics';
import {
  type DehydratedState,
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { OverlayProvider, Toaster } from 'design-system';
import EventEmitter from 'events';
import { AnimatePresence } from 'framer-motion';
import debounce from 'lodash-es/debounce';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'tippy.js/dist/tippy.css';

// 경로 직접 넣어줌
import { CommonHead } from '@components/CommonHead/CommonHead';
import { Config } from '@components/Config/Config';
import { useRecordPreviousURL } from '@components/Layout/Floating/GlobalAppBar/components/BackLink';
import { Layout } from '@components/Layout/Layout';

import { deserialize } from '@config/react-query';
import { registCustomSuperJSON } from '@config/super-json';

import { EmitContext } from '@contexts/emitter';

import '../styles/scss/globals.scss';

const isServerSideRendered = () => {
  return typeof window === 'undefined';
};

if (process.env.NODE_ENV !== 'production' && !isServerSideRendered()) {
  import('@axe-core/react').then((axe) => {
    axe.default(React, ReactDOM, 1000);
  });
}

const DEFAULT_STALE_TIME = 10 * 60 * 1000;

const DEFAULT_CACHE_TIME = 10 * 60 * 1000;

if (typeof window !== 'undefined') {
  // load custom superjson in fastest way
  // https://github.com/vercel/next.js/discussions/36622
  document.addEventListener('DOMContentLoaded', registCustomSuperJSON);

  // posthog config
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || '', {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
    person_profiles: 'identified_only',
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug(); // debug mode in development
    },
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: DEFAULT_STALE_TIME,
      gcTime: DEFAULT_CACHE_TIME,
      refetchOnWindowFocus: false,
    },
  },
});

const useSetPosthogPageView = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => posthog?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);
};

const useSetAppSizeCSSVars = () => {
  const router = useRouter();

  useEffect(() => {
    const appEl = document.querySelector('#__next');
    const appBarEl = document.querySelector('#app-bar');
    const bottomNavBarEl = document.querySelector('nav#BottomNavBar');

    const setAppSizeCSSVars = debounce(() => {
      const appWidth = appEl ? appEl.clientWidth : 0;
      const appBarHeight = appBarEl ? appBarEl.clientHeight || 52 : 0;
      const bottomNavBarHeight = bottomNavBarEl ? bottomNavBarEl.clientHeight : 0;
      document.documentElement.style.setProperty('--app-height', '100dvh');
      document.documentElement.style.setProperty('--app-width', `${appWidth}px`);
      document.documentElement.style.setProperty('--app-bar-height', `${appBarHeight}px`);
      document.documentElement.style.setProperty(
        '--bottom-nav-bar-height',
        `${bottomNavBarHeight}px`,
      );
      document.documentElement.style.setProperty(
        '--app-content-height',
        `calc(100dvh-${appBarHeight + bottomNavBarHeight}px)`,
      );
    }, 100);

    router.events.on('routeChangeComplete', setAppSizeCSSVars);
    window.addEventListener('resize', setAppSizeCSSVars);

    setAppSizeCSSVars();

    return () => {
      router.events.off('routeChangeComplete', setAppSizeCSSVars);
      window.removeEventListener('resize', setAppSizeCSSVars);
    };
  }, [router]);
};

function MyApp(props: AppProps<{ dehydratedState: DehydratedState }>) {
  const { Component, pageProps } = props;
  const [eventEmitter] = useState(new EventEmitter());

  useSetPosthogPageView();

  useSetAppSizeCSSVars();

  useRecordPreviousURL();

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={deserialize(pageProps.dehydratedState, queryClient)}>
        <PostHogProvider client={posthog}>
          <EmitContext.Provider value={eventEmitter}>
            <Toaster>
              <OverlayProvider>
                <CommonHead />
                <Config />
                <Analytics />
                <div id="portal" />
                <Layout>
                  <AnimatePresence initial={false} mode="wait">
                    <Component {...pageProps} />
                  </AnimatePresence>
                </Layout>
              </OverlayProvider>
            </Toaster>
          </EmitContext.Provider>
        </PostHogProvider>
      </HydrationBoundary>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default MyApp;
