'use client';

import { ReactNode, createContext, useCallback, useState } from 'react';

import * as RadixToast from '@radix-ui/react-toast';
import { AnimatePresence } from 'framer-motion';
import { throttle } from 'lodash-es';
import { cn } from 'tailwind-config';
import { v4 as uuidv4 } from 'uuid';

import { Toast, ToastProps } from './Toast';

export const ToastContext = createContext<{
  openToast: (arg: ToastProps | ((close: () => void) => ToastProps)) => void;
}>({
  openToast: () => {
    throw new Error(
      "You can't call openToast() outside of a <ToastProvider> – add it to your tree.",
    );
  },
});
/**
 * Radix의 Toast를 사용했다가, Framer-motion과 통합이 힘들어서 (순정 radix는 animation을 컨트롤 하기 힘듬)
 * https://buildui.com/recipes/animated-toast 다음 조합을 참고해서 만듬.
 */
export function Toaster({ children }: { children: ReactNode }) {
  const [messages, setMessages] = useState<({ id: string } & ToastProps)[]>([]);

  const close = useCallback((id: string) => {
    setMessages((toasts) => toasts.filter((t) => t.id !== id));
  }, []);

  const throttledOpenToast = useCallback(
    throttle((arg: ToastProps | ((close: () => void) => ToastProps)) => {
      const { type = 'single' } = arg as ToastProps;
      const id = uuidv4();

      const toastProps = typeof arg === 'function' ? arg(() => close(id)) : arg;

      setMessages((prevToasts) => {
        if (type === 'multiple') {
          return [
            ...prevToasts,
            {
              id,
              ...toastProps,
            },
          ];
        }

        if (prevToasts.length < 1 || prevToasts[prevToasts.length - 1].title !== toastProps.title) {
          return [
            ...prevToasts,
            {
              id,
              ...toastProps,
            },
          ];
        }

        return prevToasts;
      });
    }, 500), // 500ms (0.5 seconds) throttle delay
    [],
  );

  return (
    <RadixToast.Provider duration={3000}>
      <ToastContext.Provider value={{ openToast: throttledOpenToast }}>
        {children}
      </ToastContext.Provider>
      <AnimatePresence>
        {messages.map((toast) => (
          <Toast key={toast.id} onClose={() => close(toast.id)} {...toast} />
        ))}
      </AnimatePresence>
      <RadixToast.Viewport
        className={cn(
          'fixed bottom-0 left-[50%] z-[9999] flex translate-x-[-50%] flex-col-reverse gap-3',
        )}
      />
    </RadixToast.Provider>
  );
}
