import { useCallback } from 'react';

import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';
import { useQueryClient } from '@tanstack/react-query';

import { getCouponCode, getCouponCodeWithSchoolName } from '../calls';

export const useGetCouponCode = (code = '') => {
  const queryClient = useQueryClient();
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.COUPON_CODE, code],
    queryFn: getCouponCode,
    enabled: code.length > 0,
  });

  const getManually = useCallback(
    async ({ code, schoolName }: { code?: string; schoolName?: string }) => {
      let couponCode: CouponCode | null = null;

      try {
        // 1. 코드로 직접 가져오기
        if (code) {
          if (queryClient.getQueryData([QUERY_KEY.COUPON_CODE, code])) {
            couponCode =
              queryClient.getQueryData<CouponCode>([QUERY_KEY.COUPON_CODE, code]) ?? null;
          } else {
            couponCode = await getCouponCode({
              queryKey: [QUERY_KEY.COUPON_CODE, code],
              queryClient,
            });
          }
        }
        // 2. 학교 이름으로 가져오기
        else if (schoolName) {
          couponCode = await getCouponCodeWithSchoolName({
            schoolName,
            queryClient,
          });
        }
      } catch (error) {
        if (error instanceof Error) {
          if (error.cause === 'invalid-school-name') {
            // 23FW 이벤트로 학교 이름으로 못가져 올 시에 기본 쿠폰을 담아줌
            // 쿠폰을 분리한 이유는 쿠폰 이름을 개인화하고 tracking이 용이하게 하기 위함.
            // https://linear.app/tgsociety/issue/TGS-94/%5B%EB%8C%80%ED%95%99%EC%83%9D%EC%9D%B4%EB%B2%A4%ED%8A%B8%5D-%EB%8C%80%ED%95%99%EC%83%9D-%EC%9D%B4%EB%B2%A4%ED%8A%B8-%EB%B0%B0%ED%8F%AC
            couponCode = await getCouponCode({
              queryKey: [QUERY_KEY.COUPON_CODE, '23FW-UNIV'],
              queryClient,
            });
          }
        }
      }

      if (!couponCode) throw new Error('해당하는 쿠폰이 없습니다.');

      queryClient.setQueryData([QUERY_KEY.COUPON_CODE, couponCode.id], couponCode);

      return couponCode;
    },
    [queryClient],
  );

  return { ...result, getManually };
};
