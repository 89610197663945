import { useEffect } from 'react';

import { useGetUser } from '@db/collections';
import { isEmpty } from 'lodash-es';
import { useRouter } from 'next/router';

import { LogEvent } from './LogEvent';

let documentReferrer = '';

const GA_MAX_REFERRER_LENGTH = 300;

export const GoogleAnalytics = () => {
  const router = useRouter();
  const { user, isUserExist } = useGetUser();

  useEffect(() => {
    if (!isUserExist || !user) return;
    // 다음 속성을 보기
    // https://help.airbridge.io/en/references/s2s-event#send-in-app-events-body-params-4
    LogEvent.유입.$로그인(user);
  }, [isUserExist]);

  /**
   * next router 단위의 페이지 그룹을 content_group으로 설정합니다.
   */
  useEffect(() => {
    if (typeof window === 'undefined' || !window.gtag) return;

    // 전체 URL을 가져옵니다.
    const fullURL = document.location.href;

    // URL 객체를 생성합니다.
    const url = new URL(fullURL);

    // 도메인 부분을 추출합니다.
    const domain = url.origin;

    /**
     * GA에서 page_location의 길이 제한이 1000자여서 넘어갈 경우 GA가 아예 안찍히는 문제가 있습니다.
     * 따라서 SPA 에서 주로 사용하는 방법인 브라우저 history 기반으로 자동으로 page_view를 찍어주는 방식이 아닌,
     * router.pahname 변경 시에 직접 page_view를 찍어주는 방식으로 변경합니다.
     */
    LogEvent.유입.pageView({
      content_group: router.pathname,
      page_location: domain + router.pathname,
      raw_page_location: fullURL.slice(0, GA_MAX_REFERRER_LENGTH),
      raw_page_pathname: window.location.pathname,
      // 기본 document.referral 대신 직접 페이지 레퍼러를 정의헤서 사용합니다. (처음은 제외)
      ...(!isEmpty(documentReferrer)
        ? { page_referrer: documentReferrer.slice(0, GA_MAX_REFERRER_LENGTH) }
        : {
            page_referrer: document.referrer.slice(0, GA_MAX_REFERRER_LENGTH),
          }),
      send_to: 'G-HTBLW14T84',
    });

    documentReferrer = domain + router.pathname;
  }, [router.pathname]);

  return <></>;
};
