import { useRouter } from 'next/router';
import Script from 'next/script';

export const ADSense = () => {
  const router = useRouter();
  return (
    <>
      {!router.pathname.includes('purchase') && (
        <Script
          async
          src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9018600439614344'
          crossOrigin='anonymous'></Script>
      )}
    </>
  );
};

export default ADSense;
