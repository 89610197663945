import { QUERY_KEY } from '@db/constants';
import { QueryModel } from '@db/query-models';
import { getDataWithReactQuery } from '@db/utils';
import { db } from 'firebase-config';
import { arrayUnion, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import {
  DEPOSIT,
  convertTo만원단위,
  같이취득2명할인금액,
  같이취득3명할인금액,
} from 'shared-values';

import { Discount, SelectedRange, SelectedTime, getDiscountPrice } from '../../../base-models';
import { getAcademy } from '../../Academy';
import { User } from '../../User';
import { EventAcademy } from './event-academy';
import { EventMember } from './event-member';

export class EventRoom extends QueryModel {
  eventAcademy: EventAcademy;
  member: EventMember[];
  timeLimit: Date;
  id: string;
  discount: Discount;

  constructor(input: DataModel<EventRoomData>) {
    const { eventAcademy, member, timeLimit, id, queryClient, queryKey } = input;
    super({ queryClient, queryKey, instanceConstructor: EventRoom, className: 'EventRoom' });

    this.eventAcademy = new EventAcademy(eventAcademy);
    this.member = member.map((m) => new EventMember(m));
    this.timeLimit = timeLimit;
    this.id = id;
    this._queryClient = queryClient;
    this.discount = new Discount({
      discountPrice:
        this.readyUserCount === 1 || this.readyUserCount === 0
          ? 0
          : this.readyUserCount === 2
          ? 같이취득2명할인금액
          : 같이취득3명할인금액,
      ...(this.eventAcademy.togetherEventTable
        ? {
            discountPercent: this.eventAcademy.togetherEventTable.getPercent(this.readyUserCount),
          }
        : {}),
      discountType: 'together',
      discountSource: eventAcademy.togetherEventTable ? 'simulation' : 'dt',
      promotionName: `운전선생 ${
        eventAcademy.togetherEventTable ? '동시등록' : '같이취득'
      } 이벤트 할인`,
      typeId: this.id,
      academyId: eventAcademy.ref.id,
      // 같이취득은 조건 없음
      lessonConditions: [],
    });
  }

  get isExpired() {
    return new Date(this.timeLimit).getTime() - new Date().getTime() <= 0;
  }

  // 리더는 방 마다 무조건 하나씩 존재해야한다.
  // event-room이 존재하면 무조건 리더도 존재함.
  // 예외 처리를 하더라도 바깥에서 진행해주자. 여기는 순수하게 존재하는 것들만!
  get leaderUser(): EventMember {
    const leader = this.member.find(({ isLeader }) => isLeader);
    if (!leader) throw Error('Err: 이 방에 리더가 없습니다. 방을 삭제해주세요.');
    return leader;
  }

  get readyUserCount(): number {
    if (!this.member) return 0;
    return this.member.filter(({ state, isPaid }) => state === 'ready').length;
  }

  get currentDiscountAmount(): string {
    return this.getDiscountAmount(this.readyUserCount);
  }
  get nextDiscountAmount(): string {
    return this.getDiscountAmount(this.member.length + 1);
  }
  get is전액결제(): boolean {
    return this.eventAcademy.paymentType === 'entire';
  }

  public getDiscountAmount(memberCount: number) {
    if (this.eventAcademy.togetherEventTable) {
      return `${
        Number(
          this.eventAcademy.togetherEventTable.getPercent(memberCount) === 0
            ? this.eventAcademy.togetherEventTable.highestPercent
            : this.eventAcademy.togetherEventTable.getPercent(memberCount),
        ) * 100
      }%`;
    }
    if (memberCount === 2) return convertTo만원단위(같이취득2명할인금액);
    return convertTo만원단위(같이취득3명할인금액);
  }

  public getPrice(memberId: string): DiscountReturn {
    const findMember = this.member.find((v) => v.id === memberId);
    if (!findMember) throw new Error('해당하는 멤버가 없습니다.');
    const { lessonInfo } = findMember;
    if (!lessonInfo)
      return {
        원금: 0,
        할인금: 0,
        최종금: 0,
        운전선생할인금: 0,
        시뮬레이션할인금: 0,
      };

    const { lessonPrice } = lessonInfo;

    const is전액결제 = this.is전액결제;

    return getDiscountPrice({
      price: !is전액결제 ? DEPOSIT : lessonPrice,
      discounts: [this.discount],
    });
  }

  public getCurrentUserMember(uid: string | null): EventMember | null {
    return this.member.find(({ id }) => id === uid) ?? null;
  }

  public isAnyMemberReady(uid: string): boolean {
    const currentMember = this.getCurrentUserMember(uid);

    if (!currentMember) return false;

    return this.member
      .filter((m) => m.id !== uid)
      .filter((m) => m.joinedAt >= currentMember.joinedAt)
      .some((m) => m.state === 'ready');
  }

  public async setAlerted(userId: string) {
    const eventRoomRef = doc(db, 'EventRoom', this.id);
    const newMember = this.member.map((m) => {
      if (m.id === userId) {
        return {
          ...m.get(),
          isAlerted: true,
        };
      }
      return m.get();
    });
    await updateDoc(eventRoomRef, {
      member: newMember,
    });
    this._setData({
      member: newMember,
    });
  }

  public async getAcademy() {
    const academyRef = this.eventAcademy.ref;
    const academyId = academyRef.id;
    const queryKey = [QUERY_KEY.ACADEMY, academyId];

    return await getDataWithReactQuery({
      queryClient: this._queryClient,
      queryKey,
      queryFn: getAcademy,
    });
  }

  public async addMember(user: User) {
    if (!this._queryClient) throw new Error('queryClient is not defined');

    const { uid, name } = user;
    const eventAcademyRef = this.eventAcademy.ref;
    const eventRoomRef = doc(db, 'EventRoom', this.id);
    const userRef = doc(db, 'User', uid);

    const userNewTogether = {
      togetherEvent: {
        academy: eventAcademyRef,
        eventRoom: eventRoomRef,
      },
    };

    this._queryClient.setQueryData<User | undefined>(
      [QUERY_KEY.CURRENT_USER],
      (user) =>
        user &&
        new User({
          ...user.get(),
          ...userNewTogether,
          queryClient: this._queryClient,
          queryKey: [QUERY_KEY.CURRENT_USER],
        }),
    );

    await updateDoc(eventRoomRef, {
      member: arrayUnion({
        id: uid,
        isLeader: false,
        isAlerted: false,
        name,
        lessonInfo: null,
        selectedTimes: [],
        selectedRange: null,
        state: 'selecting',
        isPaid: false,
        joinedAt: new Date(),
      }),
    });

    await updateDoc(userRef, {
      ...userNewTogether,
    });
  }

  public async selectLesson({
    user,
    lessonInfo,
    selectedTime,
    selectedRange,
  }: {
    user: User;
    lessonInfo: LessonInfo;
    selectedTime?: SelectedTime | null;
    selectedRange?: SelectedRange | null;
  }) {
    const newMember = this.member.map((v) => {
      if (v.id === user.uid) {
        return {
          ...v.get(),
          lessonInfo,
          state: 'ready',
          isAlerted: false,
          ...(selectedTime && { selectedTimes: [selectedTime.get()] }),
          ...(selectedRange && { selectedRange: selectedRange.get() }),
        };
      }
      return v.get();
    });
    await updateDoc(doc(db, 'EventRoom', this.id), {
      member: [...newMember],
    });
  }

  public async deleteUser(user: User) {
    const isLeader = this.member.some((v) => v.id === user.uid && v.isLeader);
    const newMember = this.member.filter((v) => v.id !== user.uid);

    const eventRoomRef = doc(db, 'EventRoom', this.id);
    const userDocRef = doc(db, 'User', user.uid);

    if (isLeader) {
      for await (const m of this.member) {
        const memberDocRef = doc(db, 'User', m.id);
        await updateDoc(memberDocRef, {
          togetherEvent: null,
        });
      }
      await deleteDoc(eventRoomRef);
    } else {
      await updateDoc(eventRoomRef, {
        member: newMember.map((v) => ({
          ...v.get(),
        })),
      });
      await updateDoc(userDocRef, {
        togetherEvent: null,
      });
    }
  }
}
