import { Spacer } from 'design-system';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';

import { useMounted } from '@hooks';

import { GlobalAppBar } from './Floating';

const BottomNavbar = dynamic(() =>
  import('./Floating/BottomNavbar').then((v) => ({ default: v.BottomNavbar })),
);
const GlobalModal = dynamic(
  () => import('./GlobalModal').then((v) => ({ default: v.GlobalModal })),
  {
    ssr: false,
  },
);
const GlobalReview = dynamic(
  () => import('./GlobalModal').then((v) => ({ default: v.GlobalReview })),
  {
    ssr: false,
  },
);
const GlobalBottomFloatingBar = dynamic(
  () =>
    import('./Floating/GlobalBottomFloatingBar').then((v) => ({
      default: v.GlobalBottomFloatingBar,
    })),
  {
    ssr: false,
  },
);
const GlobalLoading = dynamic(
  () => import('./Instant/GlobalLoading').then((v) => ({ default: v.GlobalLoading })),
  {
    ssr: false,
  },
);

const isPC = typeof window !== 'undefined' && document.documentElement.clientWidth > 500;

const PCWebToApp = () => {
  const mounted = useMounted();

  // hydration 때문에 client side 에서만 렌더링
  if (!mounted || !isPC) return null;

  return (
    <>
      <section>
        <div className="fixed left-[calc(50%+282px)] top-[50%] -translate-y-[50%]">
          <div className="flex gap-8">
            <Link href="https://apps.apple.com/us/app/%EC%9A%B4%EC%A0%84%EC%84%A0%EC%83%9D-%EC%9A%B4%EC%A0%84%ED%95%99%EC%9B%90-%EC%B5%9C%EC%A0%80%EA%B0%80-%EC%98%88%EC%95%BD/id6444027313">
              <Image
                src="/pc-web-to-app/apple-icon.png"
                width={40}
                height={40}
                alt="앱스토어 이미지"
              />
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=com.drivingteacher">
              <Image
                src="/pc-web-to-app/playstore-icon.png"
                width={40}
                height={40}
                alt="플레이스토어 이미지"
              />
            </Link>
          </div>
          <Spacer className="h-16" />
          <Image
            src="/pc-web-to-app/qr.png"
            width={124}
            height={124}
            alt="QR 코드 이미지"
            className=""
          />
          <Spacer className="h-16" />
          <h2 className="text-new-Title2 whitespace-pre">
            앱으로 간편하게
            <br />
            운전학원 예약해보세요!
          </h2>
        </div>
        <div className="fixed bottom-32 left-[calc(50%+282px)] w-[100px]">
          <Image src="/pc-web-to-app/text-logo.png" width={100} height={27.5} alt="운전선생 로고" />
          <Spacer className="h-8" />
          <Link href={'https://info.drivingteacher.co.kr'} target="_blank">
            <span className="text-new-Body1-bold text-new-gray-500 whitespace-nowrap underline">
              입점 및 제휴 문의
            </span>
          </Link>
        </div>
      </section>
    </>
  );
};

export const Layout = ({ children }: { children: React.ReactElement }) => {
  return (
    <>
      {/* Modal */}
      <GlobalModal />
      <GlobalReview />
      {/* Instant */}
      <GlobalLoading />
      {/* Floating */}
      <GlobalBottomFloatingBar />
      <main className="break-keep">
        <PCWebToApp />
        <GlobalAppBar />
        {children}
      </main>
      <BottomNavbar />
    </>
  );
};
